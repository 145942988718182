import * as React from "react";
import "../../gatsby-browser.js";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { motion } from "framer-motion";
// import { lan_data } from "../component/Local/local";
import { lan } from "../lan/service";
import CourseDefaultBanner from "../static/img/courses-bottom-background.jpg";
import LevelUpIcon from "../static/img/icons/level-up.png";
import { Link } from "gatsby";
import HourglassIcon from "../static/img/icons/hourglass.png";
import ContactForm from "../component/Courses/ContactForm.js";
import MetaTags from "../component/MetaTags.jsx";
import Price from "../component/Price.jsx";
import JapaneseCourseCard from "../component/JapaneseCourseCard/JapaneseCourseCard.jsx";

class JapaneseClasses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      name: [],
      email: [],
      phone: [],
      message: [],
    };
  }

  getName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  getEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  getPhone = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  getMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  render() {
    const pageVariants = {
      initial: {
        opacity: 0,
      },
      in: {
        opacity: 1,
      },
      out: {
        opacity: 0,
      },
    };

    const { data, loading } = this.state;

    return (
      <>
        <Navbar />
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          <section id="courses-sec" className="course-banner">
            <div className="language-content">
              <h1 align="center">
                Learn to Code with KoderBox and Become a Coding Pro
              </h1>
              {/* <p align="center">{lan_data?.["coding"]}</p> */}
              <p align="center">{lan.en.coding}</p>
            </div>
            <div className="japanese-form">
              <ContactForm />
            </div>
          </section>
          <div className="container">
            <div className="row about-extra">
              <div align={"center"}>
                <h2 className="light-text">
                  ABOUT{" "}
                  <span className="highlighted-text">
                    OUR DEVELOPMENT COURSES
                  </span>{" "}
                </h2>
              </div>
              <div className="courses__courses row mt-5">
                <div className="col-lg-6 col-md-12 wow bounceInUp ">
                  <div className="courses__course-box ">
                    <div
                      style={{
                        backgroundImage: `url(${CourseDefaultBanner})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      className="course-box__head"
                    ></div>
                    <div className="course-box__content">
                      <div className="course-box__content--meta">
                        <div className="course-box__content--meta--level">
                          <img src={LevelUpIcon} alt="level-up" />
                          <span>Intermediate</span>
                        </div>
                        <div className="course-box__content--meta--duration">
                          <img src={HourglassIcon} alt="duration" />
                          <span>6 weeks</span>
                        </div>
                      </div>
                      <div className="course-box__content--about">
                        <h3>Fullstack Development</h3>
                        <p>Become a fullstack developer.</p>
                        <ul>
                          <li>Frontend: ReactJs.</li>
                          <li>Backend: Django.</li>
                          <li>Python concepts(Basic+Advance).</li>
                        </ul>
                      </div>

                      <hr />

                      <div
                        className="course-box__content--bottom"
                        id="python-rating"
                      >
                        <div className="course-box__content--rating d-flex align-items-center gap-2">
                          <span className="course-box__content--rating--star">
                            ⭐⭐⭐⭐⭐
                          </span>
                        </div>

                        <div className="course-box__content--price">
                          <Price originalPrice={true} inr={8500} usd={500} />
                          {/* <span>Price: </span>
                          <span>{this.state.inIndia?"₹3500/₹5000":"$200/$250"}</span> */}
                        </div>
                      </div>
                      <div className="btn-container">
                        <Link
                          to="/fullstackcourse/"
                          className="text-decoration-none "
                        >
                          <button className="pay-btn">View Course</button>
                        </Link>
                        <Price buy={true} inUrl={'00gcPb5Tw9OHgZW6oC'} usUrl={'14kg1n4Ps0e78tq6oD'} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 wow bounceInUp">
                  <div className="courses__course-box ">
                    <div
                      style={{
                        backgroundImage: `url(${CourseDefaultBanner})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      className="course-box__head"
                    ></div>
                    <div className="course-box__content">
                      <div className="course-box__content--meta">
                        <div className="course-box__content--meta--level">
                          <img src={LevelUpIcon} alt="level-up" />
                          <span>Intermediate</span>
                        </div>
                        <div className="course-box__content--meta--duration">
                          <img src={HourglassIcon} alt="duration" />
                          <span>6 weeks</span>
                        </div>
                      </div>
                      <div className="course-box__content--about">
                        <h3>ReactJs Development</h3>
                        <p>Get started with React for frontend development.</p>
                        <ul>
                          <li>1.5 Month course.</li>
                        </ul>
                      </div>

                      <hr />

                      <div className="course-box__content--bottom mt-auto">
                        <div className="course-box__content--rating d-flex align-items-center gap-2">
                          <span className="course-box__content--rating--star">
                            ⭐⭐⭐⭐⭐
                          </span>
                        </div>

                        <div className="course-box__content--price">
                          {/* <span>Price: </span>
                          <span>{this.state.inIndia?"₹3500":"$200"}</span> */}
                          <Price originalPrice={true} inr={3500} usd={200} />
                        </div>
                      </div>
                      <div className="btn-container">
                        <Link
                          to="/reactdevelopercourse/"
                          className="text-decoration-none "
                        >
                          <button className="pay-btn">View Course</button>
                        </Link>
                        <Price buy={true} inUrl={'fZe4iFgyaaSL2528wM'} usUrl={'9AQeXj4Ps3qj10Y00i'} />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="courses__course-box col-lg-4 col-md-12 wow bounceInUp"> */}
                <div className="col-lg-6 col-md-12 wow bounceInUp ">
                  <div className="courses__course-box ">
                    <div
                      style={{
                        backgroundImage: `url(${CourseDefaultBanner})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      className="course-box__head"
                    ></div>
                    <div className="course-box__content">
                      <div className="course-box__content--meta">
                        <div className="course-box__content--meta--level">
                          <img src={LevelUpIcon} alt="level-up" />
                          <span>Intermediate</span>
                        </div>
                        <div className="course-box__content--meta--duration">
                          <img src={HourglassIcon} alt="duration" />
                          <span>6 weeks</span>
                        </div>
                      </div>
                      <div className="course-box__content--about">
                        <h3>Python Development</h3>
                        <p>Python is easy to learn and use.</p>
                        <ul>
                          <li>
                            Python Basic -{" "}
                            <Price originalPrice={true} inr={3500} usd={200} />{" "}
                          </li>
                          <li>
                            Python Advance -{" "}
                            <Price originalPrice={true} inr={3500} usd={200} />
                          </li>
                        </ul>
                      </div>

                      <hr />

                      <div
                        className="course-box__content--bottom"
                        id="python-rating"
                      >
                        <div className="course-box__content--rating d-flex align-items-center gap-2">
                          <span className="course-box__content--rating--star">
                            ⭐⭐⭐⭐⭐
                          </span>
                        </div>

                        <div className="course-box__content--price">
                          <Price originalPrice={true} inr={3500} usd={200} />
                          {/* <span>Price: </span>
                          <span>{this.state.inIndia?"₹3500/₹5000":"$200/$250"}</span> */}
                        </div>
                      </div>
                      <div className="btn-container">
                        <Link
                          to="/pythondevelopercourse/"
                          className="text-decoration-none "
                        >
                          <button className="pay-btn">View Course</button>
                        </Link>
                        <Price buy={true} inUrl={'4gwcPbgya8KD5hefZf'} usUrl={'14kbL74Ps5yr7pm3cv'} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 wow bounceInUp ">
                  <div className="courses__course-box ">
                    <div
                      style={{
                        backgroundImage: `url(${CourseDefaultBanner})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      className="course-box__head"
                    ></div>
                    <div className="course-box__content">
                      <div className="course-box__content--meta">
                        <div className="course-box__content--meta--level">
                          <img src={LevelUpIcon} alt="level-up" />
                          <span>Intermediate</span>
                        </div>
                        <div className="course-box__content--meta--duration">
                          <img src={HourglassIcon} alt="duration" />
                          <span>6 weeks</span>
                        </div>
                      </div>
                      <div className="course-box__content--about">
                        <h3>Python Development (Basic+Advance)</h3>
                        <p>Python is easy to learn and use.</p>
                        <ul>
                          <li>A complete guide for python.</li>
                          {/* <li>
                            Python Basic -{" "}
                            <Price originalPrice={true} inr={3500} usd={200} />{" "}
                          </li>
                          <li>
                            Python Advance -{" "}
                            <Price originalPrice={true} inr={3500} usd={200} />
                          </li>
                          <li>
                            Python (Basic +Adv)-{" "}
                            <Price originalPrice={true} inr={5000} usd={250} />
                          </li> */}
                        </ul>
                      </div>

                      <hr />

                      <div
                        className="course-box__content--bottom"
                        id="python-rating"
                      >
                        <div className="course-box__content--rating d-flex align-items-center gap-2">
                          <span className="course-box__content--rating--star">
                            ⭐⭐⭐⭐⭐
                          </span>
                        </div>

                        <div className="course-box__content--price">
                          <Price originalPrice={true} inr={5000} usd={250} />
                          {/* <span>Price: </span>
                          <span>{this.state.inIndia?"₹3500/₹5000":"$200/$250"}</span> */}
                        </div>
                      </div>
                      <div className="btn-container">
                        <Link
                          to="/pythonAdvanceBasicCourse/"
                          className="text-decoration-none "
                        >
                          <button className="pay-btn">View Course</button>
                        </Link>
                        <Price buy={true} inUrl={'7sI16t95I6CvaByaEZ'} usUrl={'00g16tbdQ4unaBy4gA'} />
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}

                {/* <div className="courses__course-box col-lg-4 col-md-12 wow bounceInUp"> */}
                <div className="col-lg-6 col-md-12 wow bounceInUp">
                  <div className="courses__course-box ">
                    <div
                      style={{
                        backgroundImage: `url(${CourseDefaultBanner})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      className="course-box__head"
                    ></div>
                    <div className="course-box__content">
                      <div className="course-box__content--meta">
                        <div className="course-box__content--meta--level">
                          <img src={LevelUpIcon} alt="level-up" />
                          <span>Intermediate</span>
                        </div>
                        <div className="course-box__content--meta--duration">
                          <img src={HourglassIcon} alt="duration" />
                          <span>6 weeks</span>
                        </div>
                      </div>
                      <div className="course-box__content--about">
                        <h3>Django Development</h3>
                        <p>Django is a high-level Python web framework.</p>
                        <ul>
                          <li>1.5 Month course.</li>
                        </ul>
                      </div>

                      <hr />

                      <div className="course-box__content--bottom">
                        <div className="course-box__content--rating d-flex align-items-center gap-2">
                          <span className="course-box__content--rating--star">
                            ⭐⭐⭐⭐⭐
                          </span>
                        </div>

                        <div className="course-box__content--price">
                          <Price originalPrice={true} inr={5000} usd={300} />
                          {/* <span>Price: </span>
                          <span>{this.state.inIndia?"₹3500":"$200"}</span> */}
                        </div>
                      </div>
                      <div className="btn-container">
                        <Link
                          to="/djangodevelopercourse/"
                          className="text-decoration-none "
                        >
                          <button className="pay-btn">View Course</button>
                        </Link>
                        <Price buy={true} inUrl={'00g02pchU4un4da3cy'} usUrl={'6oE5mJeq2f91dNKeVh'} />
                      </div>
                    </div>
                  </div>
                </div>
                {/* </div> */}

                {/* <div className="courses__course-box col-lg-4 col-md-12 wow bounceInUp"> */}
                {/* <Link
                  to="/pythondevelopercourse/"
                  className="courses__course-box col-lg-6 col-md-12 wow bounceInUp"
                > */}
                {/* <div
                    style={{
                      backgroundImage: `url(${CourseDefaultBanner})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    className="course-box__head"
                  ></div> */}
                {/* <div className="course-box__content"> */}
                {/* <div className="course-box__content--meta">
                      <div className="course-box__content--meta--level">
                        <img src={LevelUpIcon} alt="level-up" />
                        <span>Intermediate</span>
                      </div>
                      <div className="course-box__content--meta--duration">
                        <img src={HourglassIcon} alt="duration" />
                        <span>6 weeks</span>
                      </div>
                    </div> */}
                {/* <div className="course-box__content--about">
                      <h3>Python Development</h3>
                      <p>
                        Python is a general-purpose, high-level programming 
                        language that is easy to learn and use.
                      </p>
                      <ul>
                        <li>1 Months course  -  ₹1999</li>
                        <li>1.5 Month course -  ₹2499</li>
                      </ul>

                    </div>

                    <hr /> */}

                {/* <div className="course-box__content--bottom">
                      <div className="course-box__content--rating d-flex align-items-center gap-2">
                        <span className="course-box__content--rating--star">
                          ⭐⭐⭐⭐⭐
                        </span>
                      </div>

                      <div className="course-box__content--price">
                        <span>Price: </span>
                        <span>₹2499</span>
                      </div>
                    </div> */}
                {/* </div> */}
                {/* </Link> */}
                {/* </div> */}
              </div>

              {/* <div className="about__box">
                <div className="about__img--box wow fadeInUp">
                  <img
                    src={Japanese1}
                    className="img-fluid"
                    alt=""
                    width="400"
                  />
                </div>
                <div className="about__content wow fadeInUp pt-5 pt-lg-0">
                  <p className={"service_about"}>
                    {lan_data["japanese-class"]["about"][0]}
                  </p>
                </div>
              </div> */}
            </div>
          </div>

          <header className="section-header">
            <h3 className="light-text">
              {" "}
              WHY{" "}
              <span className="highlighted-text">
                {" "}
                KODERBOX TECHNOLOGIES?
              </span>{" "}
            </h3>
          </header>
          <section
            id="services"
            className="courses"
            style={{ marginTop: "4rem" }}
          >
            <div className="container">
              <br />
              <br />
              <div className="">
                <div
                  className="col-md-12  wow bounceInUp "
                  data-wow-duration="1.4s"
                >
                  <div className="box blank_service">
                    <div className="row">
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                Our training program is led by expert
                                instructors who have extensive industry
                                experience. They possess in-depth knowledge of
                                React JS and are committed to providing
                                top-level education to help you succeed.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                We prioritize practical training to ensure you
                                acquire hands-on experience. Through real-world
                                projects, coding exercises, and interactive
                                sessions, you will gain the skills and
                                confidence needed to excel in React JS
                                development.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                Our training program is designed with a
                                job-oriented mindset. The syllabus is carefully
                                crafted to cover the most in-demand React JS
                                concepts and techniques, equipping you with the
                                skills that employers seek.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                We offer dedicated doubt-clearing sessions where
                                our instructors address your queries and provide
                                additional guidance, ensuring a clear
                                understanding of the subject matter.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                We offer our training program at an affordable
                                fee, making it accessible to a wider audience.
                                Additionally, we provide flexible scheduling
                                options to accommodate different learning needs
                                and availability.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-md-6  wow bounceInUp "
                        data-wow-duration="1.4s"
                      >
                        <div className="box box-2 blank_service">
                          <p>
                            <div className={"row"}>
                              <div className={"col-sm-12"}>
                                Take a step towards mastering React JS and
                                unlocking exciting opportunities in the world of
                                web development.
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </section>

          {/* ========================================  type =========================================== */}

          <JapaneseCourseCard viewCourse={true} />

          <br />
          <br />

          <Footer />
        </motion.div>
        <MetaTags title={"Courses | Koderbox"} />
      </>
    );
  }
}

export default JapaneseClasses;
